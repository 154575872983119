.navbar {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  z-index: 10;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  position: relative;
  /* background-color: #ffffff; */
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 30px;
}

.navbar-logo {
  width: 150px;
  height: auto;
}

.logo-link {
  display: flex;
  align-items: center;
}

.navbar-list {
  width: 250px;
  padding: 20px;
}

.nav-link {
  text-decoration: none;
  color: #000000;
  margin: 10px 0;
  transition: color 0.3s, background-color 0.3s;
}

.nav-link:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.active-link {
  color: #1976d2;
}

.nav-link .MuiListItem-root {
  padding: 10px 15px;
}

.nav-link .MuiListItemIcon-root {
  color: inherit;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-logo {
    width: 120px;
  }
}

@media (max-width: 480px) {
  .navbar-logo {
    width: 100px;
    margin: 20px 0;
    padding-top: 10px;
  }

  .MuiListItemIcon-root {
    min-width: 30px;
  }

  .MuiListItemText-root {
    display: block;
  }
}
