.checkout-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;

    .carousel-container {
      width: 100vw;
      left: -15%;
    }
  }

  
  
  
  .checkout-form {
    display: flex;
    flex-direction: column;
  }
  
  .checkout-section {
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .card-details {
    margin-top: 20px;
  }
  
  .card-details-row {
    display: flex;
    justify-content: space-between;
  }
  
  .card-details-expiry,
  .card-details-cvv {
    flex: 1;
    margin-right: 10px;
  }
  
  .card-details-cvv {
    margin-right: 0;
  }
  
  .order-summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .order-summary-item.total {
    font-weight: bold;
  }
  
  @media (max-width: 600px) {
    .checkout-container{

    
    .carousel-container{
      width: 100%;
      left: 0;
      margin-bottom: -10%;
    }
  }
    .card-details-row {
      flex-direction: column;
    }
  
    .card-details-expiry,
    .card-details-cvv {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .card-details-cvv {
      margin-bottom: 0;
    }
  }
  