.container {
  padding: 20px;
}

.search-bar-container {
  margin: 20px 0;
}

.product-list {
  max-height: 300px;
  overflow-y: auto;
}

.order-details {
  margin-top: 20px;
}

.order-details .MuiCard-root {
  margin-bottom: 10px;
}

.total-amount {
  margin-top: 20px;
  font-weight: bold;
}

.discount-field {
  margin-top: 10px;
}
