/* HomePage.css */
.home {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  overflow-x: hidden;
  padding: 0 !important;
  font-size: 14px;
  color: #000;
  background-color: #ffffff;
}

.carousel-container {
  width: 90vw; /* Set width to 90% of the viewport width */
  margin: 0 auto; /* Center align the carousel */
  height: auto;
  position: relative;
  padding: 0;
}

.simpleCarousel {
  width: 100%;
}

.simpleCarousel .swiper-slide {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.simpleCarousel .swiper-slide img {
  display: block;
  width: 100%;
  height: 30vw;
  object-fit: contain;
}

.simpleCarousel .swiper-button-next,
.simpleCarousel .swiper-button-prev {
  color: #007bff; /* Customize arrow color */
}

.simpleCarousel .swiper-pagination-bullet {
  background: #007bff; /* Customize pagination bullet color */
}

.simpleCarousel .swiper-pagination {
  bottom: 10px; /* Adjust the bottom position as needed */
}

@media (max-width: 640px) {
  .simpleCarousel .swiper-slide {
    height: 200px; /* Adjust height for mobile */
  }
}

@media (max-width: 768px) {
  .carousel-container {
    margin-top: -5.1rem;
  }
  
  .simpleCarousel .swiper-slide {
    height: 250px; /* Adjust height for tablet */
  }
  .simpleCarousel .swiper-slide img {
    height: 70vw;
  }
}

.tabs-root {
  border: 1px solid #673ab7;
  border-radius: 15px;
  padding: 5px;
  display: inline-flex; /* To make the width dynamic based on the content */
}

.tabs-container {
  display: flex;
  justify-content: center; /* Center the tabs container */
  object-fit: contain;
}

.tabs-indicator {
  display: none;
}

.tab-root {
  text-transform: none;
  min-width: auto; /* Adjust tab width */
  padding: 12px 16px;
  border-radius: 15px;
  outline: none; /* Remove the default focus outline */
}

.tab-root:focus {
  outline: none; /* Remove the focus outline */
}

.tab-root.Mui-selected {
  background-color: #673ab7;
  color: white !important;
  border-radius: 15px;
}

.tab-selected {
  color: white;
}

.info-card {
  position: relative;
  height: 450px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  margin-top: 30px;
  background-size: cover;
  background-position: center;
  color: white;
  overflow: visible; /* Allow overflow to make the badge visible */
}

.badge {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black; /* Change background color to black */
  color: white; /* Change text color to white */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.info-card-content {
  background: rgba(0, 0, 0, 0.6);
  padding: 16px;
  border-radius: 15px;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 15px;
}

.quote-icon {
  font-size: 40px;
  color: #673ab7;
  opacity: 0.6;
}
