.offer-details-container {
  padding: 20px;
}

.banner-preview {
  margin-bottom: 20px;
  object-fit: cover;
}

.list-item {
  display: flex;
  align-items: center;
}

.selected-product-card {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.selected-product-card img {
  width: 100%;
  height: 140px;
  object-fit: contain;
}

.selected-product-card .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-results-list {
  max-height: 400px;
  overflow-y: auto;
}
