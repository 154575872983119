.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-paper {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .login-logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .login-logo {
    width: 100px; /* Adjust as needed */
    height: auto;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-submit {
    margin-top: 20px;
  }
  