.audit-logs-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiTableCell-head {
  font-weight: bold;
  background-color: #f5f5f5;
}

.MuiTableCell-body {
  vertical-align: top;
}

.truncate {
  max-width: 200px; /* Adjust this value to the desired width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .MuiTableCell-body {
    font-size: 14px;
  }

  .MuiTableCell-body img {
    width: 40px;
    height: 40px;
  }

  .MuiButton-containedPrimary {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
  .audit-logs-container {
    padding: 10px;
  }

  .MuiTableCell-head,
  .MuiTableCell-body {
    padding: 8px;
  }

  .MuiTypography-h4 {
    font-size: 24px;
  }
}
