.profile-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.profile-paper {
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.progress-bar-container {
  width: 100%;
  margin-top: 20px;
}

.progress-bar {
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.progress-bar.silver .MuiLinearProgress-bar {
  background-color: #c0c0c0;
}

.progress-bar.gold .MuiLinearProgress-bar {
  background-color: #ffd700;
}

.profile-grid {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

.profile-save-button {
  margin-top: 20px;
  max-width: 400px;
}

.benefit-card {
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.silver {
  background-color: #e0e0e0 !important;
}

.gold {
  background-color: #fff4cc !important;
}

.benefit-icon {
  font-size: 50px;
  margin-bottom: 10px;
  color: #ff7043;
}

@media (max-width: 768px) {
  .profile-container {
    width: 100%;
    padding: 20px;
  }
  .profile-paper,
  .profile-grid,
  .profile-save-button {
    max-width: 100%;
  }
  .benefit-card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .profile-container {
    padding: 10px;
  }
  .profile-paper,
  .profile-grid,
  .profile-save-button {
    max-width: 100%;
  }
  .benefit-card {
    width: 100%;
  }
  .benefit-icon {
    font-size: 40px;
  }
}
