.order-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.order-info-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.order-info-card, .order-summary-card {
  width: 48%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.order-summary-card .summary-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.order-summary-card .summary-divider {
  margin: 10px 0;
}

.order-summary-card .summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.order-summary-card .summary-total {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.order-status {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.status-select {
  margin-top: 10px;
}

.status-spinner {
  margin-left: 10px;
}

.custom-input {
  position: relative;
  margin-top: 16px;
}

.datalist-input {
  width: 100%;
  padding: 16.5px 14px;
  font-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.datalist-input:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
  outline: none;
}


.products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.product-card {
  width: 250px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
