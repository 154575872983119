.practitioners-container {
    padding: 40px 0;
    background: linear-gradient(to right, #ffffff, #f8f9fa);
  }
  
  .title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .subtitle {
    text-align: center;
    margin-bottom: 40px;
    color: #34495e;
  }
  
  .practitioner-paper {
    padding: 20px;
    text-align: left;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .practitioner-paper:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .practitioner-avatar {
    width: 80px;
    height: 80px;
    border: 2px solid #4caf50;
  }
  
  .practitioner-name {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 5px;
  }
  
  .practitioner-qualification {
    color: #34495e;
    margin-bottom: 5px;
  }
  
  .practitioner-contact {
    color: #4caf50;
  }
  
  .map-image {
    width: 100px;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .practitioner-paper {
      margin-bottom: 20px;
    }
  
    .map-image {
      max-width: 100%;
    }
  }
  