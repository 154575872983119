.search-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .search-bar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-bar {
    max-width: 600px;
    width: 100%;
  }
  
  .loading-spinner {
    display: block;
    margin: 20px auto;
  }
  
  .search-results {
    width: 90%;
    max-width: 1360px;
    margin: 20px auto;
  }
  
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 25px; /* Add spacing between the cards */
    justify-content: center;
  }
  
  @media (max-width: 1280px) {
    .search-bar {
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .search-bar-container {
      flex-direction: column;
      align-items: center;
    }
  
    .search-bar {
      width: 100%;
    }
  }
  