.listproduct {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 50px;
  margin: 30px;
  border-radius: 6px;
  background: #fff;
}

.listproduct-category-filter {
  margin-bottom: 20px;
  width: 200px;
}

.listproduct-table-container {
  width: 100%;
}

.listproduct-product-icon {
  height: 80px;
}

.listproduct-remove-icon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media(max-width: 800px) {
  .listproduct {
    box-sizing: border-box;
    width: 95%;
    padding: 10px 30px;
    margin: 20px auto;
  }

  .listproduct-product-icon {
    height: 60px;
  }
}
