.login-background {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.login-logo {
  width: 100px;
  margin-bottom: 20px;
}

.paper-container {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-flipper-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flipper-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
  margin-left: 50px;
  margin-top: -350px; /* Adjust this value to move the flippers up */
}

@media (max-width: 600px) {
  .image-flipper-container {
    display: none;
  }

  .paper-container {
    flex-direction: column;
  }

  .flipper-wrapper {
    margin-top: 0;
  }
}
