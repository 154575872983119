.product-display {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin-bottom: -100px;
}

.product-display-left {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-display-thumbnails {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  min-height: 100px;
  min-width: 300px;
  gap: 10px;
  margin-bottom: 20px;
}

.thumbnail {
  width: 60px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.thumbnail:hover, .thumbnail.selected {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-display-main-image {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.main-image:hover {
  transform: scale(1.02);
}

.product-display-right {
  flex: 1;
  min-width: 300px;
  padding: 20px;
}

.product-title {
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.product-packsize {
  margin-bottom: 20px;
  color: #666;
}

.product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.product-tag {
  background-color: #007BFF;
  color: #fff;
}

.product-tag:hover {
  background-color: #0056b3;
}

.product-features {
  list-style: disc; /* Change to bulleted list */
  padding-left: 20px; /* Add left padding to indent the bullets */
  margin-bottom: 20px;
}

.product-features li {
  margin-bottom: 5px;
  font-size: 16px;
  color: #555;
}

.add-to-cart-btn {
  background-color: #28a745;
  color: #fff;
}

.out-of-stock-btn {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}

.add-to-cart-btn:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .product-display {
    flex-direction: column;
    align-items: center;
  }

  .product-display-left, .product-display-right {
    min-width: 100%;
  }

  .product-display-thumbnails {
    justify-content: center;
  }

  .thumbnail {
    width: 50px;
    height: 50px;
  }

  .product-display-main-image {
    max-width: 300px;
  }

  .product-display-right {
    padding: 15px;
  }
}
