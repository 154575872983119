.featured-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .create-button {
    margin-bottom: 20px;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .submit-button {
    margin-top: 20px;
  }
  
  .table-container {
    margin-top: 20px;
  }
  
  .table-container table {
    width: 100%;
  }
  