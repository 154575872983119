.about-us-container {
    padding: 40px 0;
    background: linear-gradient(to right, #ffffff, #f8f9fa);
  }
  
  .title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .subtitle {
    text-align: center;
    margin-bottom: 40px;
    color: #34495e;
  }
  
  .description {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
    color: #7f8c8d;
  }
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

  }
  
  .sections {
    margin-bottom: 40px;
  }
  
  .section-paper {
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .section-paper:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .section-icon {
    font-size: 100px !important;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .section-title {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .section-text {
    color: #7f8c8d;
  }
  
  .additional-sections {
    margin-top: 40px;
  }
  
  @media (max-width: 768px) {
    .section-paper {
      margin-bottom: 20px;
    }
  }
  