.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  width: 100%;
  max-width: 250px;
  height: 100vh;
  background: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.sidebar-top {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
  padding: 10px 15px;
  border-radius: 6px;
  background: #F6F6F6;
  gap: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.sidebar-item:hover {
  background: #e0e0e0;
}

.sidebar-item img {
  width: 24px;
  height: 24px;
}

.sidebar-item p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.sidebar-bottom {
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .sidebar {
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 20px 0;
    justify-content: space-around;
  }
  
  .sidebar-top {
    flex-direction: row;
    align-items: center;
  }
  
  .sidebar-logo {
    display: none;
  }
  
  .sidebar-item {
    margin: 0;
    padding: 10px;
  }
  
  .sidebar-bottom {
    margin: 0;
  }
}
