.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.banner-preview {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.sales-card {
  transition: transform 0.3s;
}

.sales-card:hover {
  transform: scale(1.05);
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}