.product-tabs {
  /* background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  min-height: 300px; /* Increased height */
}

.tabs {
  border-bottom: 1px solid #e0e0e0;
}

.tab {
  text-transform: none;
  font-weight: bold;
  color: #555;
  transition: color 0.3s ease;
}

.tab:hover {
  color: #007bff;
}

.tab-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.tab-text {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .product-tabs {
    padding: 15px;
  }

  .tab-content {
    padding: 15px;
    min-height: 150px; /* Adjusted height for smaller screens */
  }

  .tab-text {
    font-size: 14px;
  }
}
