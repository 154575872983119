.change-password-background {
    background: #f4f6f8;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .paper-container {
    padding: 20px;
  }
  