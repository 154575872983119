.product-display {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    /* background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  }
  
  .product-display-left {
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .product-display-thumbnails {
    display: flex;
    min-height: 100px;
    min-width: 300px;
    gap: 10px;
    margin-bottom: 20px;
    overflow-x: auto;
  }
  
  .thumbnail {
    width: 60px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .thumbnail:hover, .thumbnail.selected {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .product-display-main-image {
    width: 100%;
  
    max-width: 400px;
    margin-bottom: 20px;
  }
  
  .upload-button {
    margin-top: 10px;
  }
  
  @media (max-width: 600px) {
    .productdisplay {
      flex-direction: column;
      align-items: center;
    }
  
    .productdisplay-main-img {
      max-width: 200px;
    }
  }
  
  
  
  .upload-button {
    margin-top: 10px;
  }
  
  .productdisplay-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
    gap: 20px;
  }
  
  .product-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .product-tabs {
    margin-top: 20px;
    height: 150px;
    overflow-y: auto;
    width: 100%;
    max-width: 1200px;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  
  .related-products {
    width: 100%;
    max-width: 1200px;
  }
  
  .related-products-container {
    display: flex;
    gap: 40px;
  }
  
  .related-product-card {
    width: 300px;
    text-align: left;
  }
  
  .related-product-card .MuiTypography-h6 {
    font-size: 1rem;
  }
  
  .related-product-card .MuiTypography-body2 {
    font-size: 0.875rem;
  }
  
  .related-product-card .MuiCardMedia-root {
    height: 180px;
  }
  
  @media (max-width: 600px) {
    .productdisplay {
      flex-direction: column;
      align-items: center;
    }
  
    .productdisplay-main-img {
      max-width: 200px;
    }
  
    .related-products-container {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .related-product-card {
      width: 100%;
    }
  
    .product-tabs {
      height: auto;
    }
  
    .tab-content {
      height: auto;
    }
  
    .MuiTabs-root {
      flex-wrap: wrap;
    }
  
    .MuiTab-root {
      font-size: 0.75rem;
      line-height: 1.2;
      max-width: 25%;
      text-align: center;
    }
  }
  