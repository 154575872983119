.order-history-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.order-history-container .MuiTypography-h4 {
  margin-bottom: 20px;
  text-align: center;
}

.order-card {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 8px;
}

.order-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.order-header .order-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: capitalize;
}

.order-status.pending {
  background-color: #ff9800;
  color: white;
}

.order-status.completed {
  background-color: #4caf50;
  color: white;
}

.order-status.cancelled {
  background-color: #f44336;
  color: white;
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.product-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-details img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.product-info {
  display: flex;
  flex-direction: column;
}

.order-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-footer .MuiTypography-h6 {
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .order-card {
    padding: 15px;
  }

  .product-details img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .order-header, .order-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .order-header {
    margin-bottom: 15px;
  }

  .order-footer .MuiTypography-h6 {
    margin-bottom: 10px;
  }
}
