.product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 40px;
  margin-top: 2%;
}

.product-tabs {
  margin-top: 20px;
  height: 150px;
  overflow-y: auto;
  width: 100%;
  max-width: 1200px;
}

.tab-content {
  margin-top: 20px;
}

.related-products {
  width: 100%;
  max-width: 1200px;
}

.related-products-container {
  display: flex;
  gap: 40px;
}

.related-product-card {
  width: 300px;
  text-align: left;
}

.related-product-card .MuiTypography-h6 {
  font-size: 1rem;
}

.related-product-card .MuiTypography-body2 {
  font-size: 0.875rem;
}

.related-product-card .MuiCardMedia-root {
  height: 180px;
}

@media (max-width: 600px) {
  .product-top-section {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .related-products-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .related-product-card {
    width: 100%;
  }

  .product-tabs {
    height: auto;
  }

  .tab-content {
    height: auto;
  }

  .MuiTabs-root {
    flex-wrap: wrap;
  }

  .MuiTab-root {
    font-size: 0.75rem;
    line-height: 1.2;
    max-width: 25%;
    text-align: center;
  }
}
