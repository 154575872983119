/* CSS for the Cart and Overlay */

.cart-container {
  padding: 20px;
}

.cart-title {
  margin-bottom: 20px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-cart-icon {
  margin-right: 20px;
}

.cart-item {
  padding: 10px;
  position: relative;
}

.cart-item.out-of-stock {
  background-color: #f8d7da !important; /* Light red background */
  border-color: #f5c6cb !important; /* Red border */
}

.cart-item-image {
  width: 60px;
}

.cart-item-price {
  display: flex;
  flex-direction: column;
}

.discounted-price {
  color: red;
}

.original-price {
  text-decoration: line-through;
  color: gray;
}

.cart-item-quantity-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-item-quantity {
  width: 50px;
  margin: 0 10px;
}

.cart-summary-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.cart-total, .promo-code {
  padding: 20px;
  flex: 1;
}

.cart-total-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.checkout-button {
  width: 200px;
  margin-top: 20px;
}

.promo-code {
  background: #eaeaea;
}

.promo-code-box {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.out-of-stock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  z-index: 1;
}

@media (max-width: 600px) {
  .cart-title2 {
    /* font-size: 1.5rem; */
    margin-bottom: 20px !important;
  }

  .cart-header {
    margin-bottom: 10px;
  }

  .cart-item {
    padding: 5px;
  }

  .cart-item-image {
    width: 50px;
  }

  .cart-item-quantity {
    width: 40px;
  }

  .cart-total, .promo-code {
    padding: 15px;
  }

  .checkout-button {
    margin-top: 15px;
    width: 100%;
  }

  .cart-summary-container {
    flex-direction: column;
  }

  .cart-total-item {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }

  .cart-total-item.typography-h6 {
    margin-top: 10px;
  }
}


