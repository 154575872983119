/* EditFeatured.css */

.container {
    padding: 20px;
  }
  
  .heading {
    margin-bottom: 20px;
  }
  
  .text-field {
    margin-bottom: 20px;
  }
  
  .product-list,
  .featured-list {
    margin-top: 20px;
  }
  
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-content {
    display: flex;
    align-items: center;
  }
  
  .card-avatar {
    margin-right: 20px;
  }
  
  .card-text {
    flex-grow: 1;
  }
  
  .card-actions {
    text-align: right;
  }
  
  .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .snackbar {
    width: 100%;
  }
  