/* Sales.css */
.carousel-container {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .sales-content {
    width: 90%;
    max-width: 1360px;
    margin: 20px auto;
    padding: 20px;
  }
  
  .sale-section {
    margin-bottom: 40px;
  }
  
  .sale-section h5 {
    margin-bottom: 20px;
  }
  
  @media (max-width: 1280px) {
    .sales-content {
      width: 95%;
    }
  }
  
  @media (max-width: 800px) {
    .sales-content {
      width: 100%;
    }
  }
  