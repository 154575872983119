html,
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  background: #eee;
}

.mySwiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 70px; /* Increase bottom padding to accommodate pagination */
}

.mySwiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 25%;
  height: 25%;
}

.mySwiper .swiper-slide img {
  display: block;
  width: 100%;
}

.mySwiper .swiper-pagination {
  bottom: 16px; /* Adjust the bottom position as needed */
}

@media (max-width: 640px) {
  .mySwiper .swiper-slide {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 768px) {
  .mySwiper .swiper-slide {
    width: 250px;
    height: 250px;
  }
}
