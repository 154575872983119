.admin-stats-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .page-title {
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .stat-card {
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .chart-card {
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 400px; /* Adjust height as needed */
  }
  
  .tabs {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .chart-card {
      height: 300px; /* Adjust height for smaller screens */
    }
  }
  