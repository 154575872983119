.product-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.product-card-media {
  object-fit: contain !important;
  /* object-fit: cover; */
  border-bottom: 1px solid #eee;
  transition: transform 0.3s ease;
}

.product-card:hover .product-card-media {
  transform: scale(1.1);
}

.out-of-stock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(84, 79, 79, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
  font-size: 24px;
}

.discount-sticker {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  padding: 5px 10px;
  border-radius: 50px;
  z-index: 2;
}

.product-card-content {
  padding: 16px;
}

.product-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card-subtitle {
  font-size: 14px;
  color: #757575;
  margin-bottom: 12px;
}
