.products-page {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 300px;
  background-size: contain;
  background-position: center;
  margin-bottom: 20px;
}

.page-title {
  margin: 20px 0;
}

.tile-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tile {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect ratio */
  margin: 10px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform-style: preserve-3d;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.tile:hover {
  transform: scale(1.05) rotate(3deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 600px) {
  .tile-container {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-left: 5% !important;
  }

  .tile {
      width: calc(80% - 10px);
      padding-top: calc(80% - 10px); /* Maintain aspect ratio */
      margin: 4px;
  }

  .banner {
      height: 200px;
  }

  .page-title {
      font-size: 1.5rem;
      text-align: center;
  }
}
