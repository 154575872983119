/* src/components/PinVerification.css */
.pin-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.pin-verification-paper {
  padding: 2rem;
  width: 300px;
  text-align: center;
}
