
  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    background-color: #385624; /* Background color for better visibility */
    color: #fff; /* Text color */
  }
  
  .marquee {
    display: flex;
    animation: scroll 25s linear infinite;
  }
  
  .marquee span {
    display: inline-block;
    flex-shrink: 0;
    padding-right: 2rem; /* Space between repeated text */
  }
  
  @keyframes scroll {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  