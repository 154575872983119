/* Categories.css */
.shopcategory-banner {
    display: block;
    margin: 30px auto;
    width: 80%;
}

.shopcategory-indexSort {
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}

.shopcategory-sort {
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
}

.shopcategory-indexSort p span {
    font-weight: 600;
}

.shopcategory-products {
    width: 90%;
    max-width: 1360px;
    justify-content: center;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.item {
    width: 21%;
}

.item img {
    width: 100%;
    margin-left: -50px;
}

.item p {
    margin: 6px 0px;
}

.item-prices {
    display: flex;
    gap: 15px;
}

.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}

@media (max-width: 1280px) {
    .shopcategory-banner {
        margin: 20px auto;
        width: 90%;
    }
    .shopcategory-indexSort {
        width: 90%;
        margin: auto;
    }
    .shopcategory-sort {
        padding: 5px 10px;
        font-size: 12px;
    }
    .shopcategory-indexSort p {
        font-size: 12px;
    }
}

@media (max-width: 800px) {
    .shopcategory-banner {
        width: 95%;
    }
    .shopcategory-loadmore {
        margin: 60px auto;
        width: 150px;
        height: 40px;
        font-size: 13px;
    }
    .item {
        width: 35%;
    }
    .item-price-old {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .item p {
        font-size: 14px;
    }
    .item-price-new {
        font-size: 14px;
    }
}
