.footer {
    width: 100%;
    color: black;
    text-align: center;
    padding: 16px;
    border-top: 1px solid #ddd;
    margin-top: 30px;

}

.logo-section {
    text-align: center;
    padding: 16px;
}

.logo-image {
    max-width: 100px;
    height: auto;
    margin-bottom: 8px;
}

.footer-text {
    margin-bottom: 4px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 1px;
    margin-top: 8px;
}

.social-icon img {
    width: 24px;
    height: 24px;
}

.subscription {
    text-align: center;
    padding: 16px;
}

.subscription-title {
    margin-bottom: 16px;
}

.subscription-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
}

.email-input {
    flex: 1;
    max-width: 400px;
    border-radius: 25px;
    overflow: hidden;
}

.email-input .MuiOutlinedInput-root {
    border-radius: 25px;
}

.email-input .MuiOutlinedInput-input {
    padding: 12px 14px;
}

.email-input .MuiInputAdornment-positionEnd {
    margin-right: 0;
}

.company-links {
    text-align: center;
    padding: 16px;
}

.company-title {
    font-weight: bold;
    margin-bottom: 8px;
}

.company-link {
    cursor: pointer;
    display: block;
    margin-top: 4px;
}

.disclaimer {
    background-color: green;
    color: white;
    padding: 8px 0px 8px 0px;
    margin-top: 0px;
    /* margin-bottom: -10%; */
    margin-left: -1%;
    width: 98.75vw;
    overflow-x: hidden !important;
    white-space: nowrap;
}

@media (max-width: 600px) {
    .footer {
        padding: 8px;
    }

    .logo-section,
    .subscription,
    .company-links {
        text-align: center;
    }

    .logo-image {
        max-width: 80px;
    }

    .subscription-title {
        font-size: 16px;
    }

    .subscription-form {
        flex-direction: column;
        gap: 4px;
    }

    .email-input {
        max-width: 100%;
    }

    .submit-button {
        width: 100%;
    }

    .company-links {
        margin-top: 16px;
    }

    .company-link {
        margin-top: 4px;
    }
}
