.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
}

.logo-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.logo {
  max-width: 200px; /* Increased logo size */
  transition: opacity 0.3s;
  padding-bottom: 40px;
  padding-top: 20px;
}

.logo.hidden {
  opacity: 0;
}

.menu-list {
  display: flex;
  justify-content: center;
  margin-left: auto; /* Align to the left of the logo */
}

.menu-item {
  padding: 16px;
}

.menu-item-text {
  font-size: 1.25rem; /* Increased text size */
  font-weight: bold; /* Added bold font */
}

.search-bar-container {
  margin: 0 20px;
  width: 300px;
}

.search-bar-container.expanded {
  width: 100%;
}

.icon-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Increased spacing between icons */
}

.shopping-basket-icon,
.account-box-icon,
.menu-icon {
  font-size: 2.5rem; /* Increased icon size */
  color: black !important; /* Set icon color to black */
}

.login-button {
  margin-left: 10px;
}

.popover-card {
  width: 140px;
  height: 180px; /* Made height equal to width for square aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popover-card-media {
  width: 120px;
  height: 140px;
  margin: auto;
  object-fit: cover; /* Ensure images maintain aspect ratio */
}

.popover-card-content {
  text-align: center;
}

/* Styles for About Us icons */
.about-us-icon {
  font-size: 100px;
  color: black; /* Ensure icons are black */
}

.drawer-list-item-icon {
  min-width: 50px;
}

.drawer-card-media {
  width: 40px;
  height: 40px;
  margin: auto;
}

/* Custom spacing for specific popover items */
.popover-item {
  margin-right: 5px; /* Standard space */
}

.popover-item-large-space {
  margin-right: 48px; /* Larger space for separating specific items */
}

/* Responsive styles */
@media (max-width: 1200px) {

  .search-bar-container {
    display: none;
  }

  .search-bar-container2 {
    display: block;
    width: 100%; /* Full width for mobile */
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }

  .horizontal-align {
    display: flex;
    align-items: center; /* Align items vertically centered */
  }

  .menu-list {
    display: none; /* Hide the menu list on mobile */
  }

  .icon-buttons {
    display: flex;
    align-items: center;
    gap: 5px; /* Reduced spacing between icons for mobile */
  }

  .logo {
    max-width: 100px; /* Smaller logo for mobile */
    margin: 10px 0;
  }
}
