.image-flipper {
  perspective: 1000px;
  width: 100%;
  max-width: 300px; /* Adjust the max-width as needed */
  margin: 20px 0; /* Add some margin for spacing */
  position: relative; /* Ensure it's properly positioned */
}

.flipper {
  position: relative;
  width: 100%;
  padding-top: 60%; /* 5:3 aspect ratio */
  transition: transform 0.6s;
  transform-style: preserve-3d;
  z-index: 1; /* Default z-index */
}

.flipper.flip {
  transform: rotateY(180deg);
}

.flipper img {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px; /* Optional for rounded corners */
  transition: transform 0.3s ease-in-out;
}

.flipper .front {
  transform: rotateY(0deg);
}

.flipper .back {
  transform: rotateY(180deg);
  z-index: 0; /* Ensure it's behind by default */
}

@media (max-width: 600px) {
  .image-flipper {
    display: none;
  }
}
